import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import Quote from '@components/IndustryDataType/Quote';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Telecommunications_Cover.jpg';
import NTTDocomo from './assets/NTT_Docomo.png';
import metaImage from './assets/meta.jpg';

const TelecomPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="industry-telecommunications"
      helmet={{
        title: 'TileDB for Telecommunications',
        description: 'Use TileDB to innovate and optimize with customer, device and connectivity data.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Telecommunications"
      title="Innovate and optimize operations with customer, device and connectivity data"
      heroImage={BackgroundImage}
      icon={industries.telecom.icon}
      overview={
        <>
          <p>
            5G and IoT offer the opportunity to transform every industry and deliver an explosion of new services tapping a variety of data
            sources.
          </p>
          <p>
            Network monitoring, mobile spatial statistics, digital twin simulations, churn prediction - are just a few cases where leading
            telecoms turn to TileDB. See how a universal platform for all data types delivers extreme performance, security and scalability
            for the most demanding environments.
          </p>
        </>
      }
      relatedItems={[dataTypes.timeSeries, dataTypes.lidar, dataTypes.dataframes]}
    >
      <Quote
        logo={<img src={NTTDocomo} alt="ntt docomo" style={{ width: 200 }} />}
        author={{ name: 'Yuko Sasahara', title: 'CEO' }}
        text="Our R&D team developing Mobile Spatial Statistics, population statistical data generated from mobile network operation data, is working with TileDB to handle time-series data modeled as versioned, sparse arrays. The processing and data management improvements are critical to advanced analytics and statistical predictions in a variety of use cases within NTT DOCOMO"
      />
    </IndustryDataTypeTemplate>
  );
};

export default TelecomPage;
